<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" :more-btn="false" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="操作人">
              <el-input v-model="searchForm.operationName" placeholder="请输入要搜索的操作人" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" :index="indexMethod" />
        </template>
      </ics-table-inner>
    </div>
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import utils from '@/assets/js/utils'
export default {
  components: {},
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: true,
      baseSearchForm: {
        operationName: ''
      },
      columnOption: [
        { label: '操作类型', prop: 'operationTypeName', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '操作内容', prop: 'content', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '备注', prop: 'operationAnnotation', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '操作人', prop: 'operationName', formatter: this.utils.isEffective, minWidth: 100 },
        { label: '操作时间', prop: 'recordTime', formatter: this.utils.isEffective, minWidth: 100 },
        ]
    }
  },
  computed: {
    createdArr: utils.computedDate('startDate', 'endDate')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.operating.listPage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    }
  }
}
</script>

<style>

</style>
